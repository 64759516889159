import React from 'react';
import shortid from  "shortid";
const UnikubeSection8 = (props) => {

    return (
        <section className="section-8">
            <div className="container">
                <div className="title-and-description-block">
                    <div className="row">
                        <div className="col-lg-3"></div>
                        <div className="col-lg-18">
                            <h1 className="h1 section-title normal-spacing">{props.mainTitle}</h1>
                            <p className="section-description">{props.mainDescription}</p>
                        </div>
                        <div className="col-lg-3"></div>
                    </div>
                </div>
                <div className="image-block-1">
                    <div className="image-items">
                        {props.imageslist?.map((object, i) => {
                            return (
                                <div className="item" key={shortid.generate()}>
                                    <img
                                        src={object.image1x?.sourceUrl}
                                        srcSet={object.image2x?.sourceUrl + " 2x, " + object.image1x?.sourceUrl + " 1x"}
                                        width={object.image1x?.width}
                                        alt={object.image1x?.altText}
                                    />
                                </div>     
                            )
                        })}
                    </div>
                </div>
                <div className="image-block-2">
                    <div className="row">
                        {props.imageslist2?.map((object, i) => {
                            return (
                                <div className="col-lg-8" key={shortid.generate()}>
                                    <div className="item">
                                        <img
                                            src={object.image1x?.sourceUrl}
                                            srcSet={object.image2x?.sourceUrl + " 2x, " + object.image1x?.sourceUrl + " 1x"}
                                            width={object.image1x?.width}
                                            alt={object.image1x?.altText}
                                        />
                                    </div>  
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default UnikubeSection8;