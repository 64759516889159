import * as React from "react";

const UnikubeSection1 = (props) => {
    return (
        <section className="section-1">
            <div className="container">
                <div className="row">
                    <div className="col-sm-24">
                        <div className="image-block">
                            <img
                                src={props.image1x?.sourceUrl}
                                srcSet={props.image2x?.sourceUrl + " 2x, " + props.image1x?.sourceUrl + " 1x"}
                                width={props.image1x?.width}
                                alt={props.image1x?.altText}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section> 
    )
}

export default UnikubeSection1;