import * as React from "react"
import ReactPlayer from 'react-player'

const UnikubeSection7 = (props) => {
    return (
        <section className="section-7">
            <div className="container">
                <div className="title-and-description-block">
                    <div className="row">
                        <div className="col-lg-4"></div>
                        <div className="col-lg-18">
                            <h1 className="h1 section-title">{props.mainTitle}</h1>
                            <p className="section-description">{props.mainDescription}</p>
                        </div>
                        <div className="col-lg-2"></div>
                    </div>
                </div>
                <div className="videos-block">
                    <div className="row">
                        <div className="col-sm-24">
                            <div className="video-block">
                                <ReactPlayer 
                                    url={props.video1Url} 
                                    width='100%' 
                                    height="100%"
                                    loop={true}
                                    playing={true}
                                    muted={true}
                                    playsinline={true}
                                />
                            </div>
                        </div>
                        <div className="col-sm-24">
                            <div className="video-block">
                                <ReactPlayer 
                                    url={props.video2Url} 
                                    width='100%' 
                                    height="100%"
                                    loop={true}
                                    playing={true}
                                    muted={true}
                                    playsinline={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="image-block-1">
                    <div className="row">
                        <div className="col-sm-24">
                            <div className="image-block">
                                <img
                                    src={props.image11x?.sourceUrl}
                                    srcSet={props.image12x?.sourceUrl + " 2x, " + props.image11x?.sourceUrl + " 1x"}
                                    width={props.image11x?.width}
                                    alt={props.image11x?.altText}
                                />
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="image-block">
                                <img
                                    src={props.image21x?.sourceUrl}
                                    srcSet={props.image22x?.sourceUrl + " 2x, " + props.image21x?.sourceUrl + " 1x"}
                                    width={props.image21x?.width}
                                    alt={props.image21x?.altText}
                                />
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="image-block">
                                <img
                                    src={props.image31x?.sourceUrl}
                                    srcSet={props.image32x?.sourceUrl + " 2x, " + props.image31x?.sourceUrl + " 1x"}
                                    width={props.image31x?.width}
                                    alt={props.image31x?.altText}
                                />
                            </div>
                        </div>
                        <div className="col-sm-24">
                            <div className="image-block">
                                <img
                                    src={props.image41x?.sourceUrl}
                                    srcSet={props.image42x?.sourceUrl + " 2x, " + props.image41x?.sourceUrl + " 1x"}
                                    width={props.image41x?.width}
                                    alt={props.image41x?.altText}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> 
    )
}

export default UnikubeSection7;