import React, {useEffect, useRef, useState} from "react";
import { graphql } from "gatsby";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import parse from 'html-react-parser';

import Layout from "../../components/layout";
import Seo from "../../components/seo";

//section
import UnikubeHeroSection from "../sections/caseStudies/unikube/UnikubeHeroSection";
import ChallengesSection from "../sections/caseStudies/ChallengesSection";
import UnikubeSection1 from "../sections/caseStudies/unikube/UnikubeSection1";
import UnikubeSection2 from "../sections/caseStudies/unikube/UnikubeSection2";
import UnikubeSection3 from "../sections/caseStudies/unikube/UnikubeSection3";
import UnikubeSection4 from "../sections/caseStudies/unikube/UnikubeSection4";
import UnikubeSection5 from "../sections/caseStudies/unikube/UnikubeSection5";
import UnikubeSection6 from "../sections/caseStudies/unikube/UnikubeSection6";
import UnikubeSection7 from "../sections/caseStudies/unikube/UnikubeSection7";
import UnikubeSection8 from "../sections/caseStudies/unikube/UnikubeSection8";
import UnikubeSection9 from "../sections/caseStudies/unikube/UnikubeSection9";
import UnikubeSection10 from "../sections/caseStudies/unikube/UnikubeSection10";
import TestimonialSection from "../sections/TestimonialSection";


const Unikube = ({data}) => {
    const mainData = data.allWpPage.nodes[0].caseStudyUnikubePageACF;
    const sectionRef = useRef();
    const [bgTransition, setBgTransition] = useState('');
    // useEffect(() => {
    //   let unmounted = false;
    //   gsap.registerPlugin(ScrollTrigger)
    //   setTimeout(() => {
    //     if (!unmounted) {
    //       gsap.set(sectionRef.current, {
    //         scrollTrigger: {
    //           trigger: '.unikube-case-study .section-5',
    //           start: "top 60%",
    //           end: "bottom 60%",
    //           ease: "ease",
    //           scrub: true,
    //           markers: false,
    //           onEnter: () => setBgTransition('unikube-bg'),
    //           onLeave: () => setBgTransition(''),
    //           onEnterBack: () => setBgTransition('unikube-bg'),
    //           onLeaveBack: () => setBgTransition(''),
    //         }
    //       });
    //     }
    //   }, 1000);
  
    //   return () => {
    //     unmounted = true;
    //   }
    // }, []);
    return(
        <Layout headerClass="white-header">
            <Seo title="Unikube" />
            <div className={"unikube-case-study page-wrapper " + bgTransition} id="unikube-casestudy-page-wrapper" ref={sectionRef}>
                <UnikubeHeroSection
                    preTitle={parse(mainData.unikubeHeroSectionPretitle)}
                    mainTitle={parse(mainData.unikubeHeroSectionTitle)}
                    image1x={
                      {
                        sourceUrl: mainData.unikubeHeroSectionImage1x.sourceUrl,
                        altText: mainData.unikubeHeroSectionImage1x.altText,
                        width: mainData.unikubeHeroSectionImage1x.width,
                      }
                    }
                    image2x={
                      {
                        sourceUrl: mainData.unikubeHeroSectionImage2x.sourceUrl,
                        altText: mainData.unikubeHeroSectionImage2x.altText,
                        width: mainData.unikubeHeroSectionImage2x.width,
                      }
                    }
                    mobileImage={
                      {
                        sourceUrl: mainData.unikubeHeroSectionMobileImage.sourceUrl,
                        altText: mainData.unikubeHeroSectionMobileImage.altText,
                        width: mainData.unikubeHeroSectionMobileImage.width,
                      }
                    }
                />
                <ChallengesSection
                  companyInfoList={mainData.unikubeChallengeSectionCompanyInfoList}
                  challengesList={mainData.unikubeChallengeSectionChallengesList}
                />
                <UnikubeSection1
                    image1x={
                      {
                        sourceUrl: mainData.unikubeSection1Image1x.sourceUrl,
                        altText: mainData.unikubeSection1Image1x.altText,
                        width: mainData.unikubeSection1Image1x.width,
                      }
                    }
                    image2x={
                      {
                        sourceUrl: mainData.unikubeSection1Image2x.sourceUrl,
                        altText: mainData.unikubeSection1Image2x.altText,
                        width: mainData.unikubeSection1Image2x.width,
                      }
                    }
                />
                <UnikubeSection2 
                    mainTitle={parse(mainData.unikubeSection2Title)}
                    mainDescription={parse(mainData.unikubeSection2Description)}
                    image1x={
                      {
                        sourceUrl: mainData.unikubeSection2Image1x.sourceUrl,
                        altText: mainData.unikubeSection2Image1x.altText,
                        width: mainData.unikubeSection2Image1x.width,
                      }
                    }
                    image2x={
                      {
                        sourceUrl: mainData.unikubeSection2Image2x.sourceUrl,
                        altText: mainData.unikubeSection2Image2x.altText,
                        width: mainData.unikubeSection2Image2x.width,
                      }
                    }
                />
                <UnikubeSection3 
                    mainTitle={parse(mainData.unikubeSection3Title)}
                    mainDescription={parse(mainData.unikubeSection3Description)}
                    image1x={
                      {
                        sourceUrl: mainData.unikubeSection3Image1x.sourceUrl,
                        altText: mainData.unikubeSection3Image1x.altText,
                        width: mainData.unikubeSection3Image1x.width,
                      }
                    }
                    image2x={
                      {
                        sourceUrl: mainData.unikubeSection3Image2x.sourceUrl,
                        altText: mainData.unikubeSection3Image2x.altText,
                        width: mainData.unikubeSection3Image2x.width,
                      }
                    }
                />
                <UnikubeSection4 
                    mainTitle={parse(mainData.unikubeSection4Title)}
                    mainDescription={parse(mainData.unikubeSection4Description)}
                    image1x={
                      {
                        sourceUrl: mainData.unikubeSection4Image1x.sourceUrl,
                        altText: mainData.unikubeSection4Image1x.altText,
                        width: mainData.unikubeSection4Image1x.width,
                      }
                    }
                    image2x={
                      {
                        sourceUrl: mainData.unikubeSection4Image2x.sourceUrl,
                        altText: mainData.unikubeSection4Image2x.altText,
                        width: mainData.unikubeSection4Image2x.width,
                      }
                    }
                />
                <UnikubeSection5 
                  mainTitle={parse(mainData.unikubeSection5Title)}
                  mainDescription={parse(mainData.unikubeSection5Description)}
                  image1x={
                    {
                      sourceUrl: mainData.unikubeSection5Image1x.sourceUrl,
                      altText: mainData.unikubeSection5Image1x.altText,
                      width: mainData.unikubeSection5Image1x.width,
                    }
                  }
                  image2x={
                    {
                      sourceUrl: mainData.unikubeSection5Image2x.sourceUrl,
                      altText: mainData.unikubeSection5Image2x.altText,
                      width: mainData.unikubeSection5Image2x.width,
                    }
                  }
                />
                <UnikubeSection6
                  mainTitle={parse(mainData.unikubeSection6Title)}
                  image11x={
                    {
                      sourceUrl: mainData.unikubeSection6Image11x.sourceUrl,
                      altText: mainData.unikubeSection6Image11x.altText,
                      width: mainData.unikubeSection6Image11x.width,
                    }
                  }
                  image12x={
                    {
                      sourceUrl: mainData.unikubeSection6Image12x.sourceUrl,
                      altText: mainData.unikubeSection6Image12x.altText,
                      width: mainData.unikubeSection6Image12x.width,
                    }
                  }
                  image21x={
                    {
                      sourceUrl: mainData.unikubeSection6Image21x.sourceUrl,
                      altText: mainData.unikubeSection6Image21x.altText,
                      width: mainData.unikubeSection6Image21x.width,
                    }
                  }
                  image22x={
                    {
                      sourceUrl: mainData.unikubeSection6Image22x.sourceUrl,
                      altText: mainData.unikubeSection6Image22x.altText,
                      width: mainData.unikubeSection6Image22x.width,
                    }
                  }
                  image31x={
                    {
                      sourceUrl: mainData.unikubeSection6Image31x.sourceUrl,
                      altText: mainData.unikubeSection6Image31x.altText,
                      width: mainData.unikubeSection6Image31x.width,
                    }
                  }
                  image32x={
                    {
                      sourceUrl: mainData.unikubeSection6Image32x.sourceUrl,
                      altText: mainData.unikubeSection6Image32x.altText,
                      width: mainData.unikubeSection6Image32x.width,
                    }
                  }
                />
                <UnikubeSection7
                  mainTitle={parse(mainData.unikubeSection7Title)}
                  mainDescription={parse(mainData.unikubeSection7Description)}
                  video1Url={mainData.unikubeSection7Video1Url}
                  video2Url={mainData.unikubeSection7Video2Url}
                  image11x={
                    {
                      sourceUrl: mainData.unikubeSection7Image11x.sourceUrl,
                      altText: mainData.unikubeSection7Image11x.altText,
                      width: mainData.unikubeSection7Image11x.width,
                    }
                  }
                  image12x={
                    {
                      sourceUrl: mainData.unikubeSection7Image12x.sourceUrl,
                      altText: mainData.unikubeSection7Image12x.altText,
                      width: mainData.unikubeSection7Image12x.width,
                    }
                  }
                  image21x={
                    {
                      sourceUrl: mainData.unikubeSection7Image21x.sourceUrl,
                      altText: mainData.unikubeSection7Image21x.altText,
                      width: mainData.unikubeSection7Image21x.width,
                    }
                  }
                  image22x={
                    {
                      sourceUrl: mainData.unikubeSection7Image22x.sourceUrl,
                      altText: mainData.unikubeSection7Image22x.altText,
                      width: mainData.unikubeSection7Image22x.width,
                    }
                  }
                  image31x={
                    {
                      sourceUrl: mainData.unikubeSection7Image31x.sourceUrl,
                      altText: mainData.unikubeSection7Image31x.altText,
                      width: mainData.unikubeSection7Image31x.width,
                    }
                  }
                  image32x={
                    {
                      sourceUrl: mainData.unikubeSection7Image32x.sourceUrl,
                      altText: mainData.unikubeSection7Image32x.altText,
                      width: mainData.unikubeSection7Image32x.width,
                    }
                  }
                  image41x={
                    {
                      sourceUrl: mainData.unikubeSection7Image41x.sourceUrl,
                      altText: mainData.unikubeSection7Image41x.altText,
                      width: mainData.unikubeSection7Image41x.width,
                    }
                  }
                  image42x={
                    {
                      sourceUrl: mainData.unikubeSection7Image42x.sourceUrl,
                      altText: mainData.unikubeSection7Image42x.altText,
                      width: mainData.unikubeSection7Image42x.width,
                    }
                  }
                />
                <UnikubeSection8 
                  mainTitle={parse(mainData.unikubeSection8Title)}
                  mainDescription={parse(mainData.unikubeSection8Description)}
                  imageslist={mainData.unikubeSection8ImageList}
                  imageslist2={mainData.unikubeSection8ImageList2}
                />
                <UnikubeSection9 
                  mainTitle={parse(mainData.unikubeSection9Title)}
                  mainDescription={parse(mainData.unikubeSection9Description)}
                  image11x={
                    {
                      sourceUrl: mainData.unikubeSection9Image11x.sourceUrl,
                      altText: mainData.unikubeSection9Image11x.altText,
                      width: mainData.unikubeSection9Image11x.width,
                    }
                  }
                  image12x={
                    {
                      sourceUrl: mainData.unikubeSection9Image12x.sourceUrl,
                      altText: mainData.unikubeSection9Image12x.altText,
                      width: mainData.unikubeSection9Image12x.width,
                    }
                  }
                  image21x={
                    {
                      sourceUrl: mainData.unikubeSection9Image21x.sourceUrl,
                      altText: mainData.unikubeSection9Image21x.altText,
                      width: mainData.unikubeSection9Image21x.width,
                    }
                  }
                  image22x={
                    {
                      sourceUrl: mainData.unikubeSection9Image22x.sourceUrl,
                      altText: mainData.unikubeSection9Image22x.altText,
                      width: mainData.unikubeSection9Image22x.width,
                    }
                  }
                  image31x={
                    {
                      sourceUrl: mainData.unikubeSection9Image31x.sourceUrl,
                      altText: mainData.unikubeSection9Image31x.altText,
                      width: mainData.unikubeSection9Image31x.width,
                    }
                  }
                  image32x={
                    {
                      sourceUrl: mainData.unikubeSection9Image32x.sourceUrl,
                      altText: mainData.unikubeSection9Image32x.altText,
                      width: mainData.unikubeSection9Image32x.width,
                    }
                  }
                />
                <UnikubeSection10 
                  mainTitle={parse(mainData.unikubeSection10Title)}
                  mainDescription={parse(mainData.unikubeSection10Description)}
                  image1x={
                    {
                      sourceUrl: mainData.unikubeSection10Image1x.sourceUrl,
                      altText: mainData.unikubeSection10Image1x.altText,
                      width: mainData.unikubeSection10Image1x.width,
                    }
                  }
                  image2x={
                    {
                      sourceUrl: mainData.unikubeSection10Image2x.sourceUrl,
                      altText: mainData.unikubeSection10Image2x.altText,
                      width: mainData.unikubeSection10Image2x.width,
                    }
                  }
                />
                {/* <TestimonialSection
                  extraClasses=""
                  mainTitle={parse(mainData.unikubeTestimonialSectionTitle)}
                  message={parse(mainData.unikubeTestimonialSectionDescription)}
                  name={parse(mainData.unikubeTestimonialSectionName)}
                  designation={parse(mainData.unikubeTestimonialSectionDesignation)}
                  headshot1x={
                    {
                      sourceUrl: mainData.unikubeTestimonialSectionHeadshot1x.sourceUrl,
                      altText: mainData.unikubeTestimonialSectionHeadshot1x.altText,
                      width: mainData.unikubeTestimonialSectionHeadshot1x.width,
                    }
                  }
                  headshot2x={
                    {
                      sourceUrl: mainData.unikubeTestimonialSectionHeadshot2x.sourceUrl,
                      altText: mainData.unikubeTestimonialSectionHeadshot2x.altText,
                      width: mainData.unikubeTestimonialSectionHeadshot2x.width,
                    }
                  }
                  logo={
                    {
                      sourceUrl: mainData.unikubeTestimonialSectionLogo.sourceUrl,
                      altText: mainData.unikubeTestimonialSectionLogo.altText,
                      width: mainData.unikubeTestimonialSectionLogo.width,
                    }
                  }
              /> */}
            </div>
        </Layout>
    )
}

export const UnikubeQuery = graphql`
{
    allWpPage(filter: {slug: {eq: "unikube"}}) {
        nodes {
          caseStudyUnikubePageACF {
            unikubeHeroSectionPretitle
            unikubeHeroSectionTitle
            unikubeHeroSectionImage1x {
              altText
              sourceUrl
              width
            }
            unikubeHeroSectionImage2x {
              altText
              sourceUrl
              width
            }
            unikubeHeroSectionMobileImage {
              altText
              sourceUrl
              width
            }
            unikubeChallengeSectionCompanyInfoList {
                title
                description
            }
            unikubeChallengeSectionChallengesList {
              title
              description
            }
            unikubeSection1Image1x {
                altText
                sourceUrl
                width
            }
            unikubeSection1Image2x {
              altText
              sourceUrl
              width
            }
            unikubeSection2Title
            unikubeSection2Description
            unikubeSection2Image1x {
              altText
              sourceUrl
              width
            }
            unikubeSection2Image2x {
              altText
              sourceUrl
              width
            }
            unikubeSection3Title
            unikubeSection3Description
            unikubeSection3Image1x {
              altText
              sourceUrl
              width
            }
            unikubeSection3Image2x {
              altText
              sourceUrl
              width
            }
            unikubeSection4Title
            unikubeSection4Description
            unikubeSection4Image1x {
              altText
              sourceUrl
              width
            }
            unikubeSection4Image2x {
              altText
              sourceUrl
              width
            }
            unikubeSection5Title
            unikubeSection5Description
            unikubeSection5Image1x {
              altText
              sourceUrl
              width
            }
            unikubeSection5Image2x {
              altText
              sourceUrl
              width
            }
            unikubeSection6Title
            unikubeSection6Image11x {
              altText
              sourceUrl
              width
            }
            unikubeSection6Image12x {
              altText
              sourceUrl
              width
            }
            unikubeSection6Image21x {
              altText
              sourceUrl
              width
            }
            unikubeSection6Image22x {
              altText
              sourceUrl
              width
            }
            unikubeSection6Image31x {
              altText
              sourceUrl
              width
            }
            unikubeSection6Image32x {
              altText
              sourceUrl
              width
            }
            unikubeSection7Title
            unikubeSection7Description
            unikubeSection7Video1Url
            unikubeSection7Video2Url
            unikubeSection7Image11x {
              altText
              sourceUrl
              width
            }
            unikubeSection7Image12x {
              altText
              sourceUrl
              width
            }
            unikubeSection7Image21x {
              altText
              sourceUrl
              width
            }
            unikubeSection7Image22x {
              altText
              sourceUrl
              width
            }
            unikubeSection7Image31x {
              altText
              sourceUrl
              width
            }
            unikubeSection7Image32x {
              altText
              sourceUrl
              width
            }
            unikubeSection7Image41x {
              altText
              sourceUrl
              width
            }
            unikubeSection7Image42x {
              altText
              sourceUrl
              width
            }
            unikubeSection8Title
            unikubeSection8Description
            unikubeSection8ImageList {
              image1x {
                altText
                sourceUrl
                width
              }
              image2x {
                altText
                sourceUrl
                width
              }
            }
            unikubeSection8ImageList2 {
              image1x {
                altText
                sourceUrl
                width
              }
              image2x {
                altText
                sourceUrl
                width
              }
            }
            unikubeSection9Title
            unikubeSection9Description
            unikubeSection9Image11x {
              altText
              sourceUrl
              width
            }
            unikubeSection9Image12x {
              altText
              sourceUrl
              width
            }
            unikubeSection9Image21x {
              altText
              sourceUrl
              width
            }
            unikubeSection9Image22x {
              altText
              sourceUrl
              width
            }
            unikubeSection9Image31x {
              altText
              sourceUrl
              width
            }
            unikubeSection9Image32x {
              altText
              sourceUrl
              width
            }
            unikubeSection10Title
            unikubeSection10Description
            unikubeSection10Image1x {
              altText
              sourceUrl
              width
            }
            unikubeSection10Image2x {
              altText
              sourceUrl
              width
            }
            unikubeTestimonialSectionTitle
            unikubeTestimonialSectionDescription
            unikubeTestimonialSectionHeadshot1x {
              altText
              sourceUrl
              width
            }
            unikubeTestimonialSectionHeadshot2x {
              altText
              sourceUrl
              width
            }
            unikubeTestimonialSectionName
            unikubeTestimonialSectionDesignation
            unikubeTestimonialSectionLogo {
              altText
              sourceUrl
              width
            }
          }
        }
    }
}
`
export default Unikube;
